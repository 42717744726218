/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "I recently wrote up an overview of the key things we believe at Prismatic – the things that have led to Prismatic being so unique in the world of integration platforms. Unsurprisingly, our laser focus on helping B2B software companies has dramatically shaped the platform and the way we see the integration world."), "\n", React.createElement(_components.p, null, "Hopefully it's a great read if you're looking for an overview of why integrations are such a huge problem for B2B software companies today and how the Prismatic platform can help."), "\n", React.createElement(_components.p, null, "I hope you'll check it out! Here's the link: ", React.createElement(_components.a, {
    href: "/resources/why-prismatic/"
  }, "Why Prismatic?")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
